import { FluidObject } from 'gatsby-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BackgroundImage from 'ui-kit/background-image/background-image';
import './IconList.style.scss';

export interface IconListProps {
    eyebrowText: string;
    title: string;
    subtitle: string;
    image: FluidObject;
    children: React.ReactNode | React.ReactNode[];
}

export default function IconList({ eyebrowText, title, subtitle, image, children }: IconListProps) {
    return (
        <div className={'icon-list'}>
            {image && <BackgroundImage image={image} className="header-background" />}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={8}>
                        <div className="icon-list__container">
                            <h6 className={'icon-list__header__eyebrowText'}>{eyebrowText}</h6>
                            <h2 className={'icon-list__header__title'}>{title}</h2>
                            <div className={'spacer'}></div>
                            <h4 className={'icon-list__header__subtitle col-md-6 my-0'}>{subtitle}</h4>
                            <Row className={'icon-list__list-icons my-5 w-100'}>
                                {children &&
                                    Array.isArray(children) &&
                                    children.map((child, index) => (
                                        <Col
                                            xs={6}
                                            md={3}
                                            className="icon-list__icon-container"
                                            key={`icon-list-icon-${index}`}
                                        >
                                            {child}
                                        </Col>
                                    ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
