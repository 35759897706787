import React from 'react';
import { graphql } from 'gatsby';
import Hero from 'components/hero/hero.component';
import { HeroProps } from 'components/hero/hero.props';

// backgroundColor?: string;
// backgroundImage?: FluidObject;
// eyebrowText?: string;
// title: string;
// text: string;
// image?: FluidObject;
// imagePosition?: 'top-right' | 'top-right-breakout' | 'bottom-right' | 'bottom-right-breakout';
// isTopOfFold?: boolean;
// linkTo: string;
// linkLabel: string;
// size?: string;

export default function RoundedHeroProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.RoundedHeroProviderFragment;
    sectionIndex: number;
}) {
    const heroProps: HeroProps = {
        eyebrowText: paragraph.field_rhc_eyebrow,
        title: paragraph.field_rhc_title ? paragraph.field_rhc_title : 'ERROR: Title not found',
        text: paragraph.field_rhc_body ? paragraph.field_rhc_body : 'ERROR: Text not found',
        linkTo: paragraph.field_rhc_cta_button?.uri ? paragraph.field_rhc_cta_button?.uri : '/404',
        linkLabel: paragraph.field_rhc_cta_button?.title
            ? paragraph.field_rhc_cta_button?.title
            : 'ERROR: Link Title not found',
        image: paragraph.relationships?.field_rhc_hero_image?.localFile,
        imagePosition:
            paragraph.field_rhc_image_placement === 'center' ? undefined : paragraph.field_rhc_image_placement,
        imageAltText: paragraph?.field_rhc_hero_image?.alt,
        backgroundColor:
            paragraph.field_rhc_background_color === 'white' ? undefined : paragraph.field_rhc_background_color,
        isTopOfFold: sectionIndex === 0 ? true : false,
        sectionIndex: sectionIndex
    };
    return <Hero {...heroProps} />;
}

export const query = graphql`
    fragment RoundedHeroProvider on paragraph__rounded_hero_component {
        id
        field_rhc_eyebrow
        field_rhc_title
        field_rhc_body
        field_rhc_cta_button {
            title
            uri: url
        }
        field_rhc_background_color
        field_rhc_image_placement
        relationships {
            field_rhc_hero_image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
            }
        }
        field_rhc_hero_image {
            alt
        }
    }
`;
