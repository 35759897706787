import React from 'react';
import { graphql } from 'gatsby';
import { InternalHeaderProps } from 'components/internal-header/internal-header.props';
import InternalHeader from 'components/internal-header/internal-header.component';

export default function InternalHeaderComponentProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.InternalHeaderComponentProviderFragment;
    sectionIndex: number;
}) {
    const internalHeaderProps: InternalHeaderProps = {
        eyebrowText: paragraph.field_ih_eyebrow
            ? paragraph.field_ih_eyebrow
            : 'ERROR: Eyebrow text was not passed from Drupal',
        title: paragraph.field_ih_title ? paragraph.field_ih_title : 'ERROR: Title was not passed from drupal',
        body: paragraph.field_ih_body ? paragraph.field_ih_body : 'ERROR: Text was not passed from Drupal',
        backgroundImage:
            paragraph.relationships?.field_ih_background_image?.relationships?.field_media_image?.localFile,
        sectionIndex: sectionIndex
    };
    return <InternalHeader {...internalHeaderProps} />;
}

export const query = graphql`
    fragment InternalHeaderComponentProvider on paragraph__internal_header_component {
        id
        field_ih_body
        field_ih_eyebrow
        field_ih_title
        relationships {
            field_ih_background_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                            }
                        }
                    }
                }
            }
        }
    }
`;
