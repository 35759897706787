import React, { FC, ReactElement } from 'react';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';

import withBackgroundImage from 'hoc/withBackgroundImage';

import Link from 'ui-kit/link/link';

import { HeroProps } from 'components/hero/hero.props';

import './hero.style.scss';

const Hero: FC<HeroProps> = ({
    backgroundColor,
    backgroundImage,
    eyebrowText,
    image,
    imagePosition,
    imageAltText,
    linkLabel,
    linkTo,
    size = 'large',
    text,
    text2,
    isTopOfFold,
    title,
    sectionIndex
}: HeroProps): ReactElement => {
    return (
        <Container
            fluid
            className={`hero ${backgroundColor ?? ''} ${size ?? ''} ${imagePosition ?? ''} ${
                backgroundImage ? 'has-background-image' : ''
            }`}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
            data-ga-title={title}
        >
            <Row>
                <Col xs="12" md="12" lg="6" className="d-flex align-items-center">
                    <div className="hero-content">
                        {eyebrowText && <h5 className="hero-eyebrow-text">{eyebrowText}</h5>}
                        {isTopOfFold && <h1 className="hero-title">{title}</h1>}
                        {!isTopOfFold && <h2 className="hero-title">{title}</h2>}
                        {isTopOfFold && <h3 className="hero-text">{text}</h3>}
                        {!isTopOfFold && <h4 className="hero-title">{text}</h4>}
                        {isTopOfFold && text2 && <h3 className="hero-text">{text2}</h3>}
                        {!isTopOfFold && text2 && <h4 className="hero-title">{text2}</h4>}
                        {linkTo && linkLabel && (
                            <Link
                                to={linkTo}
                                label={linkLabel}
                                variant="cta-button"
                                dataGALocation={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
                            />
                        )}
                    </div>
                </Col>

                {image && (
                    <Col xs="12" md="12" lg="6" className={`d-flex hero-image ${imagePosition ?? ''}`}>
                        <div className="hero-image-container">
                            <GatsbyImage image={getImage(image)} alt={imageAltText} />
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export const HeroWithBackgroundImage = withBackgroundImage(Hero);
export default Hero;
