import React from 'react';
import { graphql } from 'gatsby';

import { CenteredHero, CenteredHeroProps } from 'components/centered-text-hero/centered-hero.component';

export default function CenteredTextHeroProvider({
    paragraph
}: {
    paragraph: GatsbyTypes.CenteredTextHeroProviderFragment;
}) {
    const centeredTextHeroProps: CenteredHeroProps = {
        eyebrowText: paragraph.field_cth_eyebrow,
        title: paragraph.field_cth_title,
        subtitle: paragraph.field_cth_body,
        linkLabel: paragraph.field_cth_cta?.title,
        linkTo: paragraph.field_cth_cta?.uri,
        image: paragraph.relationships?.field_cth_background_image?.relationships?.field_media_image?.localFile
    };
    return <CenteredHero {...centeredTextHeroProps} />;
}

export const query = graphql`
    fragment CenteredTextHeroProvider on paragraph__centered_text_hero {
        id
        field_cth_eyebrow
        field_cth_title
        field_cth_body
        field_cth_cta {
            title
            uri: url
        }
        relationships {
            field_cth_background_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                            }
                        }
                    }
                }
            }
        }
    }
`;
