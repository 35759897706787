import React, { ReactElement } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import { RichTextProps } from './rich-text.props';

import './rich-text.style.scss';

const RichText = ({ richText }: RichTextProps): ReactElement => {
    return (
        <Container fluid className="rich-text">
            <Row>
                <Col xs="12" sm="12" lg={{ span: 8, offset: 2 }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(richText, {
                                allowedAttributes: {
                                    a: ['href', 'name', 'rel', 'target', 'data-*'],
                                    img: ['src', 'alt'],
                                    '*': ['class']
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default RichText;
