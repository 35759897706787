import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { InternalHeaderProps } from './internal-header.props';

import BackgroundImage from 'ui-kit/background-image/background-image';

import './internal-header.style.scss';

const InternalHeader = ({
    eyebrowText,
    title,
    body,
    backgroundImage,
    backgroundStyle
}: InternalHeaderProps): ReactElement => {
    return (
        <div className="internal-header">
            {backgroundImage && (
                <BackgroundImage image={backgroundImage} style={backgroundStyle} className="header-background" />
            )}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={8}>
                        <div className="internal-header-text">
                            <h5 className="internal-header-eyebrow">{eyebrowText}</h5>
                            <h1 className="internal-header-title">{title}</h1>
                            <div className="spacer" />
                            <div className="col-lg-10 col-md-10 col-12">
                                <h4 className="internal-header-body">{body}</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InternalHeader;
