import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import './contact-us-form.style.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { CONTACT_US_SCHEMA } from 'schema/contact-us';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import { accountGetContactUsMessageRoutine, accountSendContactUsMessageRoutine } from 'state/account/account.routines';
import { accountContactUsSelector, accountIsLoggedInSelector } from 'state/account/account.selectors';
import { ContactUsPayload } from 'state/account/account.services';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

export interface ContactUsFormProps {
    defaultValues?: ContactUsFormSchema;
    centerFormSubmit?: boolean;
    eyebrowText?: string;
    titleText?: string;
    buttonText?: string;
    image?: FluidObject;
}

export interface ContactUsFormSchema {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    message: string;
}
export default function ContactUsForm({
    defaultValues = { firstName: '', lastName: '', email: '', phoneNumber: '', message: '' },
    centerFormSubmit,
    eyebrowText,
    titleText,
    buttonText,
    image
}: ContactUsFormProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formName = 'ContactUsForm';
    const [messageSent, setMessageSent] = useState(false);
    const imageData = useStaticQuery(graphql`
        query {
            contactUsWoman: file(relativePath: { eq: "assets/images/contact-us-woman.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleContactUsSubmit = (values: ContactUsFormSchema, contactUs: ContactUsPayload) => {
        const message = {
            ...contactUs,
            EmailAddress: values.email,
            FirstName: values.firstName,
            LastName: values.lastName,
            Phone: values.phoneNumber,
            Body: values.message,
            CreateDate: new Date().toISOString(),
            Subject: 'Contact Us'
        };
        dispatch(
            accountSendContactUsMessageRoutine.trigger({
                ...message,
                loggedIn,
                onSuccess: () => {
                    setMessageSent(true);
                }
            })
        );
    };

    const loggedIn = useSelector(accountIsLoggedInSelector);
    useEffect(() => {
        dispatch(accountGetContactUsMessageRoutine.trigger(loggedIn));
    }, [loggedIn]);
    const contactUsMessage = useSelector(accountContactUsSelector);

    return (
        <Container fluid className={`contact-us`} id={'contact'}>
            {contactUsMessage ? (
                <Row>
                    <Col sm="12" lg={{ span: 10, offset: 1 }}>
                        <Row>
                            <Col lg="8">
                                <h5 className="contact-us-eyebrow-text text-uppercase text-center text-lg-left">
                                    {eyebrowText ? eyebrowText : t('forms.contactUs.eyebrow')}
                                </h5>
                                {messageSent ? (
                                    <>
                                        <h2 className="contact-us-title text-center text-lg-left">
                                            {t('forms.contactUs.success')}
                                        </h2>
                                        <h3 className="contact-us-follow-up text-center text-lg-left">
                                            {t('forms.contactUs.followUp')}
                                        </h3>
                                    </>
                                ) : (
                                    <>
                                        <h2 className="contact-us-title text-center text-lg-left">
                                            {titleText ? titleText : t('forms.contactUs.title')}
                                        </h2>
                                        <Formik
                                            initialValues={defaultValues}
                                            validationSchema={CONTACT_US_SCHEMA(t('countryCode'))}
                                            onSubmit={(values) => {
                                                handleContactUsSubmit(values, contactUsMessage);
                                            }}
                                        >
                                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                                <Form
                                                    id="contact-us-form"
                                                    data-ga-form-name={formName}
                                                    className="contact-us-form py-4"
                                                    autoComplete="off"
                                                >
                                                    <Row>
                                                        <Col sm={12} lg={6}>
                                                            <Text
                                                                name="firstName"
                                                                label={t('forms.contactUs.labels.firstName')}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={
                                                                    errors?.firstName
                                                                        ? t('forms.errorMessages.requiredField', {
                                                                              label: t(
                                                                                  'forms.contactUs.labels.firstName'
                                                                              )
                                                                          })
                                                                        : undefined
                                                                }
                                                                touched={touched.firstName}
                                                                defaultValue={values.firstName}
                                                            />
                                                        </Col>
                                                        <Col sm={12} lg={6}>
                                                            <Text
                                                                name="lastName"
                                                                label={t('forms.contactUs.labels.lastName')}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={
                                                                    errors?.lastName
                                                                        ? t('forms.errorMessages.requiredField', {
                                                                              label: t(
                                                                                  'forms.contactUs.labels.lastName'
                                                                              )
                                                                          })
                                                                        : undefined
                                                                }
                                                                touched={touched.lastName}
                                                                defaultValue={values.lastName}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} lg={6}>
                                                            <Text
                                                                name="email"
                                                                label={t('forms.contactUs.labels.email')}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={
                                                                    errors?.email
                                                                        ? t('forms.errorMessages.requiredField', {
                                                                              label: t('forms.contactUs.labels.email')
                                                                          })
                                                                        : undefined
                                                                }
                                                                maxLength={255}
                                                                touched={touched.email}
                                                                defaultValue={values.email}
                                                            />
                                                        </Col>
                                                        <Col sm={12} lg={6}>
                                                            <PhoneNumberText
                                                                name="phoneNumber"
                                                                label={t('forms.contactUs.labels.phone')}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={
                                                                    errors?.phoneNumber
                                                                        ? t('forms.errorMessages.requiredField', {
                                                                              label: t('forms.contactUs.labels.phone')
                                                                          })
                                                                        : undefined
                                                                }
                                                                touched={touched.phoneNumber}
                                                                value={values.phoneNumber}
                                                                defaultValue={values.phoneNumber}
                                                                countryCode={t(`countryCode`)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Text
                                                                name="message"
                                                                label={t('forms.contactUs.labels.message')}
                                                                onChange={handleChange}
                                                                errors={
                                                                    errors?.message
                                                                        ? t('forms.errorMessages.requiredField', {
                                                                              label: t('forms.contactUs.labels.message')
                                                                          })
                                                                        : undefined
                                                                }
                                                                touched={touched.message}
                                                                defaultValue={values.message}
                                                                maxLength={4096}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row
                                                        className={`${
                                                            !centerFormSubmit
                                                                ? 'd-flex justify-content-between mt-0'
                                                                : 'flex-column mt-3'
                                                        }`}
                                                    >
                                                        <Col className={!centerFormSubmit ? 'col-lg-6 col-sm-12' : ''}>
                                                            <Button
                                                                label={
                                                                    buttonText
                                                                        ? buttonText
                                                                        : t('forms.contactUs.labels.submit')
                                                                }
                                                                className="md-full"
                                                                type="submit"
                                                                variant="dark"
                                                                onClick={handleSubmit}
                                                                dataGAFormName={formName}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                            </Col>
                            <Col
                                className="contact-us-image-container"
                                xs={{ span: 8, offset: 2 }}
                                sm={{ span: 6, offset: 3 }}
                                lg={{ span: 4, offset: 0 }}
                            >
                                {image ? (
                                    <GatsbyImage image={getImage(image)} alt={''} />
                                ) : (
                                    imageData && <GatsbyImage image={getImage(imageData.contactUsWoman)} alt={''} />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </Container>
    );
}
