import React from 'react';
import { FluidObject } from 'gatsby-image';
import Link from 'ui-kit/link/link';
import { Col, Container, Row } from 'react-bootstrap';
import BackgroundImage from 'ui-kit/background-image/background-image';
import './centered-text-hero.style.scss';

export interface CenteredHeroProps {
    eyebrowText: string;
    title: string;
    subtitle: string;
    linkLabel: string;
    linkTo: string;
    image?: FluidObject;
    classNames?: string;
}

export function CenteredHero({
    eyebrowText,
    title,
    subtitle,
    image,
    linkLabel,
    linkTo,
    classNames
}: CenteredHeroProps) {
    return (
        <div className={`centered-hero ${classNames ? classNames : ''}`}>
            {image && <BackgroundImage image={image} className={`header-background`} />}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={6}>
                        <div className="centered-hero__content">
                            <h5 className={'centered-hero__eyebrowText'}>{eyebrowText}</h5>
                            <h1 className={'centered-hero__title'}>{title}</h1>
                            <div className={'spacer'}></div>
                            <h3 className={'centered-hero__text'}>{subtitle}</h3>
                            {<Link to={linkTo} label={linkLabel} variant="cta-button" />}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
