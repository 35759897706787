import React from 'react';
import { graphql } from 'gatsby';
import { FeatureHighlightProps } from 'components/feature-highlight/feature-highlight.props';
import FeatureHighlight from 'components/feature-highlight/feature-highlight.component';

export default function FeatureHighlightProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.FeatureHighlightProviderFragment;
    sectionIndex: number;
}) {
    const featureHighlightProps: FeatureHighlightProps = {
        backgroundColor: paragraph.field_fhc_background_color,
        eyebrowText: paragraph.field_fhc_eyebrow,
        headerText: paragraph.field_fhc_title,
        text: paragraph.field_fhc_body ? paragraph.field_fhc_body : '',
        image: paragraph.relationships?.field_fhc_image?.relationships?.field_media_image?.localFile,
        imagePosition: paragraph.field_fhc_image_position ? paragraph.field_fhc_image_position : 'left',
        imageAltText: paragraph.relationships?.field_fhc_image?.field_media_image?.alt,
        highlights: paragraph.field_fhc_bullet_points,
        sectionIndex: sectionIndex
    };
    return <FeatureHighlight {...featureHighlightProps} />;
}

export const query = graphql`
    fragment FeatureHighlightProvider on paragraph__feature_highlight_component {
        field_fhc_title
        field_fhc_eyebrow
        field_fhc_bullet_points
        field_fhc_body
        field_fhc_background_color
        field_fhc_image_position
        relationships {
            field_fhc_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                            }
                        }
                    }
                }
                field_media_image {
                    alt
                }
            }
        }
    }
`;
