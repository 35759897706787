import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './GridIcon.style.scss';

export interface GridIconProps {
    icon: JSX.Element;
    subtitle: string;
    bodyText: string;
}

export default function GridIcon({ icon, subtitle, bodyText }: GridIconProps) {
    return (
        <Col className="grid-icon p-0">
            <Row className="d-flex justify-content-center">
                <Col xs={12} className="grid-icon__icon">
                    {icon}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={8} className="text-center my-3">
                    <h4 className="title m-0">{subtitle}</h4>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={10} className="text-center">
                    <p className="body-text">{bodyText}</p>
                </Col>
            </Row>
        </Col>
    );
}
