import React from 'react';
import { graphql } from 'gatsby';
import IconList from 'components/icon-list/IconList';
import ListIcon from 'components/icon-list/ListIcon/ListIcon';

export default function IconListComponentProvider({
    paragraph
}: {
    paragraph: GatsbyTypes.IconListComponentProviderFragment;
}) {
    return (
        <IconList
            eyebrowText={paragraph.field_il_eyebrow}
            title={paragraph.field_il_title}
            subtitle={paragraph.field_il_subtitle}
            image={paragraph.relationships?.field_il_background_image?.relationships?.field_media_image?.localFile}
        >
            {paragraph.relationships?.field_il_icons
                ? paragraph.relationships?.field_il_icons?.map((icon, index) => {
                      return (
                          <ListIcon
                              key={`list-icon-${index}`}
                              subtitle={icon?.field_ri_title}
                              bodyText={icon?.field_ri_body}
                              icon={
                                  icon?.relationships?.field_ri_icon ? (
                                      <img
                                          src={
                                              icon?.relationships?.field_ri_icon?.relationships?.field_media_svg
                                                  ?.localFile?.publicURL
                                          }
                                          alt={icon?.relationships?.field_ri_icon?.field_media_svg?.alt}
                                      />
                                  ) : (
                                      ''
                                  )
                              }
                          />
                      );
                  })
                : null}
        </IconList>
    );
}

export const query = graphql`
    fragment IconListComponentProvider on paragraph__icon_list_component {
        id
        field_il_eyebrow
        field_il_title
        field_il_subtitle
        relationships {
            field_il_background_image {
                relationships {
                    field_media_image {
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                            }
                        }
                    }
                }
            }
            field_il_icons {
                field_ri_body
                field_ri_title
                relationships {
                    field_ri_icon {
                        relationships {
                            field_media_svg {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
