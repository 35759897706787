import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './ResourceIcon.style.scss';

export interface ResourceIconProps {
    icon: JSX.Element;
    titleText: string;
    bodyText: string;
}

export default function ResourceIcon({ icon, titleText, bodyText }: ResourceIconProps) {
    return (
        <Col xs={12} md={4} lg={2} xl={2} className="resource-icon">
            <Row className="d-flex justify-content-center">
                <Col xs={12} className="resource-icon__icon  my-1">
                    {icon}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={8} className="text-center my-1">
                    <h4 className="resource-icon__title m-0">{titleText}</h4>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={10} className="text-center">
                    <p className="resource-icon__body">{bodyText}</p>
                </Col>
            </Row>
        </Col>
    );
}
