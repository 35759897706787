import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';

import './feature-highlight.style.scss';
import { FeatureHighlightProps } from './feature-highlight.props';

const FeatureHighlight: FC<FeatureHighlightProps> = ({
    backgroundColor,
    eyebrowText,
    headerText,
    image,
    imagePosition,
    imageAltText,
    text,
    text2,
    textColor,
    highlights,
    sectionIndex
}: FeatureHighlightProps): ReactElement => {
    let backgroundTheme = null;
    switch (backgroundColor) {
        case 'light_blue': {
            backgroundTheme = 'cerulean';
            break;
        }
        case 'dark_blue': {
            backgroundTheme = 'dark';
            break;
        }
    }
    const textClass = textColor ? 'featured-highlight-content--' + textColor : '';
    const backgroundClasses = classNames('feature-highlight', backgroundTheme);
    const imageClasses = classNames('featured-highlight-image-container', imagePosition);
    const contentClasses = classNames('featured-highlight-content', textClass);

    return (
        <Container
            fluid
            className={backgroundClasses}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location="Feature Highlight"
            data-ga-title={headerText}
        >
            <Row>
                <Col xs="12" md="12" lg={{ span: 10, offset: 1 }} className="featured-highlight-container">
                    <div className={imageClasses}>
                        {image && <GatsbyImage image={getImage(image)} alt={imageAltText} />}
                    </div>
                    <div className={contentClasses}>
                        {eyebrowText && <h5 className="featured-highlight-eyebrow-text">{eyebrowText}</h5>}
                        {headerText && <h2 className="featured-highlight-header-text">{headerText}</h2>}
                        <p className="featured-highlight-description">{text}</p>
                        {text2 && <p className="featured-highlight-description">{text2}</p>}
                        <ul className="featured-highlight-highlights">
                            {highlights &&
                                highlights.map((highlight: string) => (
                                    <li className="featured-highlight-item" key={highlight}>
                                        {highlight}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default FeatureHighlight;
