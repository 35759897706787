import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import sanitizeHtml from 'sanitize-html';

import { ResourcesFileListProps } from './resources-file-list.props';

import './resources-file-list.style.scss';

export default function ResoucesFileList({
    eyebrowText,
    title,
    bodyText,
    icons,
    downloads,
    sectionIndex
}: ResourcesFileListProps) {
    return (
        <Container fluid className="resources-file-list">
            <Row className="justify-content-center">
                <Col xs={12} md={12} lg={8}>
                    <div className="resources-header">
                        <h5 className="eyebrow-text">{eyebrowText}</h5>
                        <h1 className="title">{title}</h1>
                        <div className="spacer" />
                    </div>
                </Col>
            </Row>
            <Row className="resource-icons justify-content-center">
                {React.Children.map(icons, (icon, index) => (
                    <>{icons && Array.isArray(icons) && icon}</>
                ))}
            </Row>

            <Row className="body-text justify-content-center">
                <Col xs={12} lg={4}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(bodyText, {
                                allowedAttributes: {
                                    a: ['href', 'name', 'rel', 'target', 'data-*'],
                                    img: ['src', 'alt'],
                                    '*': ['class']
                                }
                            })
                        }}
                    />
                </Col>
            </Row>

            <Row className="rule justify-content-center">
                <Col xs={12} lg={10}>
                    <div className="horiz-rule" />
                </Col>
            </Row>
            <Row className="downloads justify-content-center">
                <Col xs={12} lg={10}>
                    {React.Children.map(downloads, (download, index) => (
                        <>{downloads && Array.isArray(downloads) && download}</>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
