import React from 'react';
import { graphql } from 'gatsby';

import { RichTextProps } from 'components/rich-text/rich-text.props';
import RichText from 'components/rich-text/rich-text.component';

export default function RichTextComponentProvider({
    paragraph
}: {
    paragraph: GatsbyTypes.RichTextComponentProviderFragment;
}) {
    const richTextProps: RichTextProps = {
        richText: paragraph.field_rt_rich_text?.processed
            ? paragraph.field_rt_rich_text?.processed
            : paragraph.field_rt_rich_text?.value
    };
    return <RichText {...richTextProps} />;
}

export const query = graphql`
    fragment RichTextComponentProvider on paragraph__rich_text_component {
        id
        field_rt_rich_text {
            processed
        }
    }
`;
