import { Col } from 'react-bootstrap';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import DownloadIcon from 'ui-kit/icons/download/download-icon';

import './FileListFile.style.scss';

export interface FileListFileProp {
    file: string;
    title: string;
}

export default function FileListFile({ file, title }: FileListFileProp) {
    return (
        <Col xs={12} md={6} lg={4} className="resource-download">
            <a href={file} download>
                <div className="download-link">
                    <DownloadIcon />
                    <div className="download-title">
                        {title}
                        <ChevronIcon
                            direction="right"
                            style={{ display: 'inline-block', position: 'relative', top: '.6em', left: '.25em' }}
                        />
                    </div>
                </div>
            </a>
        </Col>
    );
}
